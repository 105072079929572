<template>
  <div class="navbar" :class="{transparent}">
    <div class="logo vadle-font">
      <span @click="navigateToHome">Vadle</span>
    </div>
    <div class="menu">
      <div class="item">
        <router-link :to="{ name:'PremiumModal', params: $route.params }">
          Premium <star-icon size="1x" class="star-icon"></star-icon>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { viewBus } from "@/utils/buses";
import { StarIcon } from "vue-feather-icons";

export default {
  name: "NavBar",
  components: {
    StarIcon
  },
  data() {
    return {
      transparent: true
    };
  },
  mounted() {
    viewBus.$on("setNavBarTransparency", this.onSetNavBarTransparency);
  },
  destroyed() {
    viewBus.$on("setNavBarTransparency", this.onSetNavBarTransparency);
  },
  methods: {
    onSetNavBarTransparency(transparent) {
      this.transparent = transparent;
    },
    navigateToHome() {
      const { locale } = this.$route.params;
      window.location.href = `/${locale}`;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.navbar {
  align-items: center;
  background-color: var(--green-color-shade);

  display: flex;
  height: 60px;
  padding: 0 var(--page-horizontal-padding);

  transition: all 0.5s ease;

  &.transparent {
    background-color: rgba(var(--green-color-shade-rgb), 0.8);
    height: 70px;

    .logo {
      font-size: 30px;
    }
  }

  * {
    color: var(--text-color-white);
    text-decoration: none;
  }

  .logo {
    flex: 1;
    font-size: 20px;
    transition: font-size 0.5s ease;

    span:hover {
      cursor: pointer;
    }
  }

  .menu {
    .item {
      a {
        align-items: center;
        display: flex;
        justify-content: center;
        text-decoration: none;

        transition: color 0.5s;

        &:hover {
          color: var(--gold-color);
        }

        .star-icon {
          fill: var(--gold-color);
          margin-left: 5px;
          stroke: var(--gold-color);
        }
      }

    }
  }
}
</style>
