<template>
  <b-modal
    id="convert-v1-license-modal"
    ref="modal"
    :title="$t('modals.convertV1License.title')"
    :cancel-title="$t('global.cancel')"
    :ok-title="$t('global.validate')"
    :hide-footer="loading||success"
    no-close-on-backdrop
    no-close-on-esc
    :ok-disabled="!captchaVerifiedToken"
    @hidden="onModalHidden"
    @ok="handleOk"
  >
    <template v-if="success">
      <div class="new-license">
        <div class="title flex-center-center">
          <span>{{ $t('global.thanksToUseVadle') }}</span>
        </div>
        <div class="license user-select">
          {{ $t('global.key') }} : {{ newLicense }}
        </div>
      </div>
    </template>
    <template v-else>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group>
          <b-form-textarea
            v-model="JWTLicenseKey"
            class="license-textarea"
            :placeholder="$t('modals.convertV1License.pasteKey')"
            rows="10"
            max-rows="30"
            autofocus
            required
          ></b-form-textarea>
        </b-form-group>

        <div class="flex-column-center-center">
          <vue-hcaptcha ref="hcaptcha" :sitekey="VUE_APP_HCAPTCHA_SITE_KEY" @verify="onCaptchaVerified"></vue-hcaptcha>
        </div>
      </form>
    </template>

    <template v-if="errorOccured" #modal-footer>
      <div class="error">
        {{ $t('modals.convertV1License.invalidOrExpiredLicense') }}
      </div>
    </template>

    <b-overlay :show="loading" no-wrap spinner-small spinner-variant="primary"></b-overlay>
  </b-modal>
</template>

<script>
import analytics from "@/classes/analytics";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
  name: "ConvertV1LicenseModal",
  components: {
    VueHcaptcha
  },
  data() {
    return {
      newLicense: null,
      JWTLicenseKey: "",
      VUE_APP_HCAPTCHA_SITE_KEY: null,
      captchaVerifiedToken: null,
      loading: false,
      errorOccured: false,
      success: null
    };
  },
  created() {
    this.VUE_APP_HCAPTCHA_SITE_KEY = process.env.VUE_APP_HCAPTCHA_SITE_KEY;
  },
  methods: {
    onCaptchaVerified(token) {
      this.captchaVerifiedToken = token;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      if(!valid) this.$refs.form.reportValidity();
      // if(!this.captchaVerifiedToken) return false;
      return valid;
    },
    resetModal() {
      this.newLicense = null;
      this.JWTLicenseKey = "";
      this.captchaVerifiedToken = null;
      this.loading = false;
      this.errorOccured = false;
      this.success = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.loading = true;
      try {
        const response = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/licenses/v1/verify`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            JWTLicenseKey: this.JWTLicenseKey.trim(),
            captchaVerifiedToken: this.captchaVerifiedToken
          })
        });

        if (!response.ok) throw new Error("Not 2xx response");

        analytics.event("successConvertV1License", "show").send();
        this.newLicense = await response.text();
        this.success = true;
      } catch (error) {
        this.success = false;
        this.errorOccured = true;
        console.error(error);

        setTimeout(() => {
          this.errorOccured = false;
        }, 5000);
      }

      this.resetHCaptcha();

      this.loading = false;
    },
    resetHCaptcha() {
      this.$refs.hcaptcha.reset();
      this.captchaVerifiedToken = null;
    },
    onModalHidden() {
      this.resetModal();
      this.$emit("hidden", this.$options.name);
    }
  }
};
</script>

<style lang="scss" scoped>
#convert-v1-license-modal {
  .new-license {
    padding: 20px 0;

    text-align: center;

    .title {
      padding: 0 0 20px;
    }

    .license {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .license-textarea {
    word-break: break-all;
  }

  .error {
    color: #ff0000;
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }
}
</style>