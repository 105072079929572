<template>
  <b-modal
    id="success-premium-modal"
    ref="modal"
    :title="`${$t('global.thank')} !`"
    centered
    scrollable
    ok-only
    :cancel="false"
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    @show="onModalShow"
    @hidden="onModalHidden"
  >
    <div v-if="showGif" class="giphy">
      <iframe
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        src="https://tv.giphy.com/thanks"
      >
      </iframe>
    </div>

    <h3>{{ $t('modals.successPremium.title') }}</h3>
    <h6>{{ $t('modals.successPremium.subtitle') }}</h6>
  </b-modal>
</template>

<script>
import analytics from "@/classes/analytics";

export default {
  name: "SuccessPremiumModal",
  data() {
    return {
      showGif: false
    };
  },
  methods: {
    onModalShow() {
      this.showGif = true;
      analytics.event("successPremium", "show").send();
    },
    onModalHidden() {
      this.showGif = false;
      this.$emit("hidden", this.$options.name);
    }
  }
};
</script>

<style lang="scss" scoped>
.giphy {
  margin: 0 0 20px;
  padding-bottom: 66%;
  position: relative;
  width: 100%;

  iframe {
    height: 100%;
    position: absolute;
    width: 100%;
  }
}
</style>