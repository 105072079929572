<template>
  <b-modal
    id="contact-modal"
    ref="modal"
    title="Contact"
    centered
    :cancel-title="$t('global.cancel')"
    :ok-title="$t('modals.contact.okButtonTitle')"
    :hide-footer="success"
    no-close-on-backdrop
    no-close-on-esc
    :ok-disabled="!captchaVerifiedToken"
    @hidden="onModalHidden"
    @ok="handleOk"
  >
    <template v-if="success">
      <div class="sent-message">
        {{ $t('modals.contact.messageSent') }}
      </div>
    </template>
    <template v-else>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="faq mb-3">
          <h5 class="mb-2">
            <b>FAQ</b>
          </h5>

          <div v-for="(faqLine, index) in faq" :key="index" class="faq-line">
            &rarr; <b-link v-b-toggle="`faq-accordion-${index}`">
              {{ faqLine.title }}
            </b-link>

            <b-collapse :id="`faq-accordion-${index}`" class="py-2" :accordion="`faq-accordion-${index}`" role="tabpanel">
              <span v-html="faqLine.response" />
              <div v-if="faqLine.convertLicenceLink">
                <router-link :to="{ name:'ConvertV1LicenseModal', params: $route.params }">
                  <b>{{ $t('modals.premium.convertV1License') }}</b>
                </router-link>
              </div>
            </b-collapse>
          </div>
        </div>

        <h5 class="mb-2">
          <b>{{ $t("modals.premium.otherQuestion") }}</b>
        </h5>

        <b-form-group
          label="Email"
          label-for="email-input"
        >
          <b-form-input
            id="email-input"
            v-model="email"
            type="email"
            autofocus
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <b-form-textarea
            id="message-textarea"
            v-model="message"
            placeholder="Message"
            rows="5"
            max-rows="30"
            required
          ></b-form-textarea>
        </b-form-group>

        <div class="flex-column-center-center">
          <vue-hcaptcha ref="hcaptcha" :sitekey="VUE_APP_HCAPTCHA_SITE_KEY" @verify="onCaptchaVerified"></vue-hcaptcha>
        </div>
      </form>

      <template v-if="loading">
        <div slot="modal-footer">
          <div v-if="success === false" class="error">
            {{ $t('modals.contact.sentError') }} 😬
          </div>
          <div v-else>
            {{ $t('modals.contact.sending') }} ...
          </div>
        </div>
      </template>
    </template>

    <b-overlay :show="loading" no-wrap spinner-small spinner-variant="primary"></b-overlay>
  </b-modal>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
  name: "ContactModal",
  components: {
    VueHcaptcha
  },
  data() {
    return {
      email: "",
      message: "",
      VUE_APP_HCAPTCHA_SITE_KEY: null,
      captchaVerifiedToken: null,
      loading: false,
      errorOccured: false,
      success: null,
      faq: [{
        convertLicenceLink: true,
        title: this.$t("modals.faq.faq1Title"),
        response: this.$t("modals.faq.faq1Response")
      }]
    };
  },
  created() {
    this.VUE_APP_HCAPTCHA_SITE_KEY = process.env.VUE_APP_HCAPTCHA_SITE_KEY;
  },
  methods: {
    onCaptchaVerified(token) {
      this.captchaVerifiedToken = token;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      if(!valid) this.$refs.form.reportValidity();
      if(!this.captchaVerifiedToken) return false;
      return valid;
    },
    resetModal() {
      this.email = "";
      this.message = "";
      this.captchaVerifiedToken = null;
      this.loading = false;
      this.errorOccured = false;
      this.success = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.loading = true;
      try {
        const response = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/contact`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: this.email,
            message: this.message,
            captchaVerifiedToken: this.captchaVerifiedToken
          })
        });

        if (!response.ok) throw new Error("Not 2xx response");
        this.success = true;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.success = false;

        setTimeout(() => {
          this.loading = false;
          this.success = null;
          this.resetHCaptcha();
        }, 3000);
      }
    },
    resetHCaptcha() {
      this.$refs.hcaptcha.reset();
      this.captchaVerifiedToken = null;
    },
    onModalHidden() {
      this.resetModal();
      this.$emit("hidden", this.$options.name);
    }
  }
};
</script>

<style lang="scss" scoped>
#contact-modal {
  .sent-message {
    font-size: 1.2em;
    font-weight: bold;
    padding: 20px 0;
    text-align: center;
  }

  .faq {
    .faq-line {
      a[aria-expanded] {
        text-decoration: none;

        &.not-collapsed {
          color: var(--blue);
        }
      }

    }
  }

  .error {
    color: #ff0000;
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }
}
</style>