import ua from "universal-analytics";
import { v4 as uuid } from "uuid";

const { VUE_APP_GOOGLE_ANALYTICS_TRACK_ID } = process.env;

if(!localStorage._ga_cid) {
  localStorage._ga_cid = uuid();
}

const analytics = ua(VUE_APP_GOOGLE_ANALYTICS_TRACK_ID, localStorage._ga_cid);

export default analytics;