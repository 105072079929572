<template>
  <div id="app">
    <!-- important id "app" as same of replaced element in index.html, see https://github.com/chrisvfritz/prerender-spa-plugin#tips--troubleshooting -->
    <nav-bar />
    <router-view />
  </div>
</template>

<style>
.navbar {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1;
}
</style>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar
  }
};
</script>
