<template>
  <div class="press-link" @click="openLink">
    <div class="website-logo-url" :class="{'padding': pressLink.websiteLogoPadding}" :style="computedStyle" />
    <div class="website-name">
      {{ pressLink.websiteName }}
    </div>
    <div class="title">
      « {{ pressLink.title }} »
    </div>
  </div>
</template>

<script>
import analytics from "@/classes/analytics";

export default {
  name: "PressLink",
  props: {
    pressLink: {
      type: Object,
      required: true
    }
  },
  computed: {
    computedStyle() {
      return `background-image: url("${this.pressLink.websiteLogoUrl}")`;
    }
  },
  methods: {
    openLink() {
      analytics.event("openPressLink", this.pressLink.websiteName).send();
      window.open(this.pressLink.websiteLink);
    }
  }
};
</script>

<style lang="scss" scoped>
.press-link {
  align-items: center;

  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 240px;
  justify-content: space-between;
  padding: 20px;

  transition: background-color 0.2s ease;
  width: 270px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;

    .website-name {
      text-decoration: underline;
    }
  }

  .website-logo-url {
    background-color: #011318;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    height: 100px;
    width: 100px;

    &.padding {
      background-size: 80%;
    }
  }

  .website-name {
    color: var(--gold-color);
    font-size: 14px;
    line-height: 30px;
  }

  .title {
    flex: 1;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    line-height: 20px;
  }
}
</style>