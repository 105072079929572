<template>
  <b-modal
    id="privacy-modal"
    ref="modal"
    title="Privacy"
    centered
    scrollable
    ok-only
    :cancel="false"
    size="lg"
    @hidden="onModalHidden"
  >
    <h3>General Information</h3>
    <p>
      We care about privacy. The information we collect is used to improve the content of our Web pages and the quality of our
      services, and is not shared with or sold to other organizations for commercial purposes.
    </p>

    <h3>Cookies</h3>

    <p>
      A cookie named _ga was used by google analytics. The Google Analytics privacy statements can be read
      <a href="https://support.google.com/analytics/answer/6004245" target="_blank">here</a>.
    </p>

    <h3>Google analytics</h3>

    <p>
      Our web site and application may make use of Google Analytics to generate statistics that will help only us improving our
      service. The Google Analytics privacy statements can be read
      <a href="https://support.google.com/analytics/answer/6004245" target="_blank">here</a>. Analytics on the application may be desactivate on the preference panel.
    </p>
  </b-modal>
</template>

<script>
export default {
  name: "PrivacyModal",
  methods: {
    onModalHidden() {
      this.$emit("hidden", this.$options.name);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>