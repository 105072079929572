import analytics from "../classes/analytics";

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";

import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:locale(en|fr)?/",
    component: {
      beforeRouteEnter: setLocale,
      beforeRouteUpdate: setLocale,
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Home
      },
      {
        path: "terms-of-use",
        name: "TermsOfUseModal",
        component: Home
      },
      {
        path: "privacy",
        name: "PrivacyModal",
        component: Home
      },
      {
        path: "contact",
        name: "ContactModal",
        component: Home
      },
      {
        path: "premium",
        name: "PremiumModal",
        component: Home
      },
      {
        path: "convert-v1-license",
        name: "ConvertV1LicenseModal",
        component: Home
      },
      {
        path: "not-compatible",
        name: "NotCompatibleModal",
        component: Home
      },
      {
        path: "success-premium",
        name: "SuccessPremiumModal",
        component: Home
      }
    ]
  },
  {
    path: "*",
    redirect: "/"
  }
];

function setLocale(to, from, next) {
  let { locale } = to.params;
  if (!locale) {
    locale = "en";
  }

  // Do something with locale, check availability of messages etc.
  i18n.locale = locale;
  next();
}

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  analytics.pageview(to.path).send();
  next();
});

export default router;
