import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";

import "./style/variables.scss";
import "./style/fonts.css";
import "./style/short.css";
import "./style/global.scss";
import i18n from "./i18n";

Vue.config.productionTip = false;

const { VUE_APP_PADDLE_VENDOR_ID } = process.env;
Paddle.Setup({ vendor: parseInt(VUE_APP_PADDLE_VENDOR_ID) });

const root = new Vue({
  mounted() {
    document.dispatchEvent(new Event("x-app-rendered"));
  },

  render: (h) => h(App),
  i18n,
  router
});

document.addEventListener("DOMContentLoaded", () => {
  root.$mount("#app");
});
