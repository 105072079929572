<template>
  <b-modal
    id="terms-of-use-modal"
    ref="modal"
    title="Terms"
    centered
    scrollable
    ok-only
    :cancel="false"
    size="lg"
    @hidden="onModalHidden"
  >
    <div class="user-select">
      <b>1. Acceptance of terms</b>
      <br> By using Vadle application, you accept and agree to be bound by the terms and provision of the Terms of Use, which
      may be updated by us from time to time without notification and you should visit http://vadle4.me/termsofuse/ from
      time to time to review the latest Terms of Use. Vadle application allows you to download video and/or audio files
      from websites exclusively for your individual and non-commercial use on the condition that you retain the copyright
      and other proprietary notices attached to the original materials or any copies of the materials. Any use of the download
      for public distributions, commercial ends or other purposes of any types on other website or networked computer environment
      is prohibited. All editions of the media on the site are copyrighted. Any unauthorized use of any materials may violate
      the copyright law, trademark law or other relevant laws.
      <br>
      <b>2. Disclaimer</b>
      <br> Vadle application gives no guarantee of the accuracy, sufficiency and reliability of such downloaded materials.
      Any materials downloaded or otherwise obtained through the use of the software is done at your own discretion and
      risk and you are solely responsible for any infection of your computer system or loss of data that results from the
      download of any such materials or use of the site. We definitely disclaim any responsibility of mistakes or omission
      of the content on the site and any explicit or implicit warranty for the materials and information, including but
      not limited to the guarantees related to ownership, non-infringement of a third party's rights, quality, and having
      no computer virus. Vadle mentions the products or solutions provided by other companies in the site just for providing
      related information.
      <br>
      <b>3. Use of Software</b>
      <br> Use of the software is subject to the terms and conditions of the license agreement. You must read and accept all
      the terms and conditions of such License Agreement before you use the software. Any use, reproduction or distribution
      of the software that is not in accordance with the License Agreement is implicitly prohibited.
      <br>
      To use Vadle, you should verify terms of use of each platform and you should only use downloaded contents for a strict personal use.<br>
      Any unauthorized use of downloaded contents with Vadle is your sole responsibility.
      <br>
      Vadle is an interface of an external software (from <a href="https://youtube-dl.org/" target="_blank">here</a>), you need to install it for Vadle to work.
      <br>
      <b>4. Feedback</b>
      <br> Any comments or materials sent to us, including without limitation feedback, such as questions, suggestions, proposals
      or any related information regarding the software, this website or any other products, programs or services ("Feadback"),
      shall be deemed to be non-confidential. Vadle shall have no obligation of any kind with respect to such Feadback
      and shall be free to reproduce, use, display, transform and distribute to others without limitation and shall be
      free to use any ideas, concepts, know-how or techniques contained in such Feedback for any purpose whatsoever, including
      but not limited to developing, making and marketing products incorporating such Feedback.
      <br>
      <b>5. Premium refund</b>
      <br> Our order process is conducted by our online reseller Paddle.com. Paddle.com is the Merchant of Record for all our orders. Paddle provides all customer service inquiries and handles returns.
      <br> full refund of your payment in the following cases:
      <br>When you stably encounter an error which is preventing you from using our software properly, and if we are not able
      to correct the error within 30 days. Please, NOTE! In this case refund is only given if you provide detailed information
      about your error, requested by our support staff, such as screenshots of error messages, log files, detailed descriptions
      of your actions, file samples, etc. If you accidentally bought the software more than once. If you can’t activate
      the application on your computer due the technical reasons.
      <br> We reserve the right to decline refund requests in the following cases:
      <br>When a user informs us about changing his decision to buy the software, saying he has uninstalled it and is not going
      to use it without giving any particular reasons. When reasons which prevent a customer from proper using of our software
      are stated clearly on our official site. (for example, if the customer requests a refund due to the absence of features
      which we never claimed to provide and which are not listed in the feature list for our applications). In Force majeure
      case, for example, if video plaform will apply changes that will make the content downloading impossible. However you can
      claim for the refund if you bought the license less than 30 days ago. In such cases, if you disagree with our grounds
      for refund refusal, you can apply to your bank and initiate a chargeback.
      <br> Once the refund or chargeback will be processed your licence key will be disabled.
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "TermsOfUseModal",
  methods: {
    onModalHidden() {
      this.$emit("hidden", this.$options.name);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>