<template>
  <b-modal
    id="not-compatible-modal"
    ref="modal"
    title="Ooops 😅"
    centered
    scrollable
    ok-only
    :cancel="false"
    size="md"
    @hidden="onModalHidden"
  >
    <div>{{ $t('modals.notCompatible.title') }}</div>
    <div>{{ $t('modals.notCompatible.subtitle') }}</div>
  </b-modal>
</template>

<script>
export default {
  name: "NotCompatibleModal",
  methods: {
    onModalHidden() {
      this.$emit("hidden", this.$options.name);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>