<template>
  <div class="home">
    <section id="home-section" class="impair">
      <b-row>
        <b-col lg="6">
          <div class="title">
            {{ $t("index.hero.title") }} <span class="vadle-font gold-color">Vadle</span>
          </div>
          <intersect @enter="onSectionIntersect(true)" @leave="onSectionIntersect(false)">
            <div class="subtitle" v-html="$t('index.hero.subtitle')" />
          </intersect>
          <div>
            <b-button variant="warning" @click="downloadApp('top')">
              {{ $t("global.downloadVadle") }}
            </b-button>
            minimal Mac OS 11 (Big Sur)
          </div>
          <div class="old-version-container">
            <b-link @click="downloadOldApp()">
              {{ $t("global.oldVersion") }}
            </b-link>
          </div>
        </b-col>
      </b-row>
    </section>
    <section id="one-two-section" class="impair">
      <div class="title">
        {{ $t("index.oneTwo.Title") }} <small>✌️</small>
      </div>
      <div class="row">
        <div class="col">
          <div class="step">
            <img src="/assets/step-1.jpg" />
            <h6 class="text">
              1. {{ $t("index.oneTwo.Step1") }}
            </h6>
          </div>
        </div>
        <div class="col">
          <div class="step">
            <img src="/assets/step-2.jpg" />
            <h6 class="text">
              2. {{ $t("index.oneTwo.Step2") }}
            </h6>
          </div>
        </div>
        <div class="col">
          <div class="step">
            <img src="/assets/step-3.jpg" />
            <h6 class="text">
              3. {{ $t("index.oneTwo.Step3") }}
            </h6>
          </div>
        </div>
      </div>
    </section>
    <section class="pair">
      <div class="title">
        {{ $t("index.youtubeDl.Title") }} <small>⚡️</small>
      </div>

      <div>
        <div v-html="$t('index.youtubeDl.line1')" />
        <div v-html="$t('index.youtubeDl.line2')" />
      </div>
    </section>
    <section class="impair">
      <div class="title">
        {{ $t("global.press") }} <small>❤️</small>
      </div>
      <b-row fluid="md">
        <b-col v-for="(pressLink, index) in pressLinks" :key="index">
          <press-link :press-link="pressLink" />
        </b-col>
      </b-row>
    </section>
    <section id="footer-section" class="pair">
      <div class="title">
        <div class="line-1 vadle-font">
          Vadle !
        </div>
        <div class="line-2 inline-flex-center-center">
          {{ $t("global.anApplicationWithLove") }} <heart-icon size="1x" class="heart-icon" />
        </div>
      </div>

      <div class="content">
        <b-button variant="warning" @click="downloadApp('bottom')">
          {{ $t("global.downloadVadle") }}
        </b-button>

        <div class="bottom">
          <div class="other-links">
            <router-link :to="{ name:'TermsOfUseModal', params: $route.params }">
              terms
            </router-link>
            -
            <router-link :to="{ name:'PrivacyModal', params: $route.params }">
              privacy
            </router-link>
            -
            <router-link :to="{ name:'ContactModal', params: $route.params }">
              contact
            </router-link>
            <div>
              * {{ $t("global.personalUse") }}
            </div>
          </div>

          <div class="paddle">
            <div class="logo">
              <img src="/assets/paddle_logo.png" />
            </div>
            <div class="text">
              Our order process is conducted by our online reseller <a href="https://paddle.com/">Paddle.com</a>.
              <a href="https://paddle.com/">Paddle.com</a> is the Merchant of Record for all our orders. Paddle provides all customer service inquiries and handles returns.
            </div>
          </div>
        </div>
      </div>
    </section>
    <terms-of-use-modal @hidden="onModalHidden" />
    <privacy-modal @hidden="onModalHidden" />
    <contact-modal @hidden="onModalHidden" />
    <premium-modal @hidden="onModalHidden" />
    <convert-v1-license-modal @hidden="onModalHidden" />
    <not-compatible-modal @hidden="onModalHidden" />
    <success-premium-modal @hidden="onModalHidden" />
  </div>
</template>

<script>
// @ is an alias to /src
import analytics from "@/classes/analytics";

import Intersect from "vue-intersect";
import { viewBus } from "@/utils/buses";
import pressLink from "./components/pressLink";
import contactModal from "./components/contactModal";
import termsOfUseModal from "./components/termsOfUseModal";
import privacyModal from "./components/privacyModal";
import premiumModal from "./components/premiumModal";
import convertV1LicenseModal from "./components/convertV1LicenseModal";
import notCompatibleModal from "./components/notCompatibleModal";
import successPremiumModal from "./components/successPremiumModal";
import { HeartIcon } from "vue-feather-icons";

import { isMacOs } from "mobile-device-detect";

export default {
  name: "Home",
  components: {
    Intersect,
    pressLink,
    HeartIcon,
    contactModal,
    termsOfUseModal,
    privacyModal,
    premiumModal,
    convertV1LicenseModal,
    notCompatibleModal,
    successPremiumModal
  },
  data() {
    return {
      routedFrom: null,
      openedModal: null,
      pressLinks: [{
        websiteLink: "https://www.opensourcemacsoftware.org/utilitaire-mac/vadle-video-audio-downloader-mac.html",
        websiteLogoUrl: "/assets/press/osms_logo.png",
        websiteName: "opensourcemacsoftware.org",
        title: "Vous allez l'adorer !"
      }, {
        websiteLink: "https://www.mac4ever.com/actu/159646_vadle-2-un-outil-gratuit-pour-telecharger-les-videos-depuis-le-web",
        websiteLogoUrl: "/assets/press/mac4ever.png",
        websiteName: "mac4ever.com",
        title: "On peut difficilement faire plus efficace !"
      }, {
        websiteLink: "https://www.macg.co/publicite/2017/10/vadle-le-telechargement-de-video-devient-un-jeu-denfant-partenaire-99931",
        websiteLogoUrl: "/assets/press/macgeneration_logo.png",
        websiteName: "macgeneration.com.fr",
        title: "le téléchargement de vidéo devient un jeu d'enfant"
      }, {
        websiteLink: "https://mac-quest.com/telecharger-video-youtube/",
        websiteLogoUrl: "/assets/press/macquest_logo.png",
        websiteName: "mac-quest.com",
        title: "Simple et efficace, l'essayer c'est l'adopter. Attention, très addictif !",
        websiteLogoPadding: true
      }, {
        websiteLink: "https://infoidevice.fr/vadle-nouveau-design-pour-application-telechargement-video-audio/", // https://infoidevice.fr/vadle-telecharger-musique-video-mac/",
        websiteLogoUrl: "/assets/press/infoidevice_logo.png",
        websiteName: "infoidevice.fr",
        title: "Vadle simplifie le téléchargement",
        websiteLogoPadding: true
      }, {
        websiteLink: "https://worldissmall.fr/2020/12/19/vadle-v2-mac/", // https://worldissmall.fr/2016/06/28/vadle-telecharger-videos-musiques-mac/
        websiteLogoUrl: "/assets/press/worldissmall_logo.png",
        websiteName: "worldissmall.fr",
        title: "D'une simplicité redoutable",
        websiteLogoPadding: true
      }, {
        websiteLink: "https://iphonconcept.com/vadle-telechargez-facilement-les-videos-et-musiques-youtube-et-de-plein-dautres-sites/",
        websiteLogoUrl: "/assets/press/iphonconcept_logo.png",
        websiteName: "iphonconcept.com",
        title: "Vadle va grandement vous simpligier la vie"
      }]
    };
  },
  watch: {
    $route(to, from) {
      if(from) this.routedFrom = from;
      this.showModal(to);
    }
  },
  mounted() {
    this.showModal(this.$route);
  },
  methods: {
    downloadOldApp() {
      window.location.href = "https://datarc.s3.eu-west-3.amazonaws.com/vadle/dmg/Vadle-2.0.4.dmg";
    },
    downloadApp(position) {
      const { locale } = this.$route.params;
      if(!isMacOs) return this.$router.push(`${locale ? "/" + locale : ""}/not-compatible`);
      analytics.event("download", `from_${position}`).send();

      window.location.href = "/api/download";
    },
    onModalHidden(modalName) {
      if(this.$router.history.current.name === modalName) {
        this.routedFrom ? this.$router.go(-1) : this.$router.replace(`/${this.$i18n.locale}/`);
      }
    },
    showModal(route) {
      let modalTopen = null;

      switch (route.name) {
        case "TermsOfUseModal":
          modalTopen = "terms-of-use-modal";
          break;
        case "PrivacyModal":
          modalTopen = "privacy-modal";
          break;
        case "ContactModal":
          modalTopen = "contact-modal";
          break;
        case "PremiumModal":
          modalTopen = "premium-modal";
          break;
        case "ConvertV1LicenseModal":
          modalTopen = "convert-v1-license-modal";
          break;
        case "NotCompatibleModal":
          modalTopen = "not-compatible-modal";
          break;
        case "SuccessPremiumModal":
          modalTopen = "success-premium-modal";
          break;

        default:
          if(route.query.fromapp && !route.query.forceUpgrade) { // old app version < 2.0
            modalTopen = "premium-modal";
          }
          break;
      }

      if(this.openedModal) {
        this.$bvModal.hide(this.openedModal);
        this.openedModal = null;
      }

      if(modalTopen) {
        this.$bvModal.show(modalTopen);
        this.openedModal = modalTopen;
      }
    },
    onSectionIntersect(visible) {
      viewBus.$emit("setNavBarTransparency", visible);
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  section {
    display: flex;
    flex-direction: column;
    padding: var(--page-horizontal-padding) var(--page-horizontal-padding) calc(var(--page-horizontal-padding) * 2) var(--page-horizontal-padding);
    position: relative;
    text-align: center;

    .old-version-container {
      margin-top: 20px;
    }

    &.pair {
      background-color: var(--green-color);
    }

    &.impair {
      color: var(--text-color-white);
    }

    > .title {
      font-size: 30px;
      font-weight: 700;
      line-height: 70px;
      margin-bottom: var(--page-horizontal-padding);
      min-height: 70px;

      &::after {
        background: #626a29;
        border: 0;
        content: '';
        display: block;
        height: 3px;
        left: calc(50% - 30px);
        position: relative;
        width: 60px;
      }
    }

    &#home-section {
      background-image: url('/assets/header-bg.jpg');
      background-position: top right;
      background-size: cover;
      color: var(--text-color-white);
      margin: 0 auto;
      min-height: 700px;
      text-shadow: rgb(39, 38, 40) 1px 1px 0;

      .title {
        font-size: 50px;
        text-shadow: 2px 2px #272628;
      }

      .subtitle {
        font-size: 25px;
        margin-bottom: 20px;
      }
    }

    &#one-two-section {
      height: auto;
      min-height: auto;

      .step {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 20px;
        margin: 10px;
        padding: 10px;

        img {
          border-radius: 12px;
          width: 300px;
        }

        .text {
          font-weight: bold;
          margin: 0;
          padding: 20px 0;
        }
      }
    }

    &#footer-section {
      min-height: 600px;

      .line-1 {
        font-size: 40px;
      }

      .line-2 {
        font-size: 20px;

        .heart-icon {
          fill: var(--text-color);
          margin-left: 5px;
        }
      }

      .bottom {
        bottom: var(--page-horizontal-padding);
        left: 0;
        position: absolute;
        width: 100%;

        .other-links {
          padding-bottom: 50px;

          ::v-deep a {
            display: inline-block;
            width: 80px;
          }
        }

        .paddle {
          opacity: 0.5;

          .text {
            font-size: 12px;
            padding: 0 30%;
            text-align: center;
          }
        }
      }

    }

    .row {
      flex: 1;

      [class^='col'] {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

}

</style>
