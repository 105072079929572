<template>
  <b-modal
    id="premium-modal"
    ref="modal"
    title="Vadle 2 - Premium"
    hide-footer
    size="md"
    no-close-on-backdrop
    no-close-on-esc
    @show="resetModal"
    @hidden="onModalHidden"
  >
    <div slot="modal-title">
      <div>{{ $t('modals.premium.buyLicense') }}</div>
      <div class="convert-v1-license-link">
        <router-link :to="{ name:'ConvertV1LicenseModal', params: $route.params }">
          {{ $t('modals.premium.convertV1License') }}
        </router-link>
      </div>
    </div>
    <div class="flex-center-center">
      <a
        v-for="product in products"
        :key="product.productId"
        class="premium-button"
        :class="{'gold': selectedProductId===product.productId}"
        @click="checkout(product)"
      >
        <template v-if="product.productPrice">
          <div class="price">{{ product.productPrice }} <small>{{ product.productCurrency }}</small></div>
        </template>
        <template v-else>
          <div class="price">
            <loader-icon size="0.8x" class="spin"></loader-icon>
          </div>
        </template>
        <div class="expire">{{ product.productExpire }}</div>
      </a>
    </div>

    <div class="premium-info-block">
      <div class="premium-info-line">
        <check-icon size="1.5x" class="icon"></check-icon> {{ $t('modals.premium.simultaneous') }}
      </div>
      <div class="premium-info-line">
        <check-icon size="1.5x" class="icon"></check-icon> {{ $t('modals.premium.maximalSpeed') }}
      </div>
      <div class="premium-info-line">
        <check-icon size="1.5x" class="icon"></check-icon> {{ $t('modals.premium.maximalQuality') }}
      </div>
      <div class="premium-info-line">
        <check-icon size="1.5x" class="icon"></check-icon> {{ $t('modals.premium.mp3Compression') }}
      </div>
      <div class="premium-info-line">
        <check-icon size="1.5x" class="icon"></check-icon> {{ $t('modals.premium.unlimitedRSS') }}
      </div>
      <div class="premium-info-line">
        <smile-icon size="1.5x" class="icon"></smile-icon> {{ $t('modals.premium.anHappyDeveloper') }}
      </div>
    </div>

    <div class="footer">
      {{ $t('modals.premium.licenseMachineUsability') }}
    </div>
  </b-modal>
</template>

<script>
import { CheckIcon, SmileIcon, LoaderIcon } from "vue-feather-icons";
import analytics from "@/classes/analytics";

export default {
  name: "PremiumModal",
  components: {
    CheckIcon,
    SmileIcon,
    LoaderIcon
  },
  data() {
    return {
      selectedProductId: null,
      products: []
    };
  },
  created() {
    this.products.push(...[{
      productId: 530135,
      productPrice: null,
      productCurrency: "",
      productExpire: `3 ${this.$t("global.month")}`
    }, {
      productId: 529590,
      productPrice: null,
      productCurrency: "",
      productExpire: `1 ${this.$t("global.year")}`
    }, /* {
      productId: 529594,
      productPrice: null,
      productCurrency: "",
      productExpire: `3 ${this.$t("global.year")}s`
    },*/ {
      productId: 639829,
      productPrice: null,
      productCurrency: "",
      productExpire: `${this.$t("global.lifetime")} !`
    }]);
  },
  methods: {
    resetModal() {
      this.selectedProductId = 529590;
      this.loadPrices();
    },
    loadPrices() {
      this.products.forEach((product) => {
        Paddle.Product.Prices(product.productId, (prices) => {
          const decimalPrice = prices.price.gross.replace(/[^0-9.]/g, "");
          product.productCurrency = prices.price.gross.replace(decimalPrice, "").replace("US", "");
          product.productPrice = decimalPrice.replace(".00", "");
        });
      });
    },
    onModalHidden() {
      this.resetModal();
      this.$emit("hidden", this.$options.name);
    },
    checkout({ productId, productPrice }) {
      analytics.event("getPremium", `click_${productPrice}`).event(`getPremium_${productPrice}`, "click").send();
      this.selectedProductId = productId;
      Paddle.Checkout.open({ product: productId });
    }
  }
};
</script>

<style lang="scss" scoped>
#premium-modal {
  .title {
    font-size: 1.2em;
    margin: 10px auto;
  }

  .premium-button {

    background-color: #f3f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 20px;

    cursor: pointer;
    display: inline-block;
    margin: 20px 0 20px 10px;
    padding: 10px;
    position: relative;
    text-align: center;
    text-decoration: none !important;
    transition: all 0.2s ease;
    width: 120px;

    &.gold {
      background-color: rgba(248, 233, 121, 0.5);
      box-shadow: 0 3px 0 #e5d728;
    }

    &:hover:not(.gold) {
      background-color: rgba(248, 233, 121, 0.25);

    }

    &:first-child {
      font-size: 0.8em;
      margin-left: 0;
    }

    &:last-child {
      font-size: 1.2em;

    }

    .price {
      font-size: 1.5em;
      font-weight: 300;
      white-space: nowrap;
    }

    .expire {
      font-size: 1.2em;
      font-weight: bold;
      white-space: nowrap;
    }

  }

  .premium-info-block {
    display: inline-block;
    display: table;
    font-size: 1.2em;
    margin: 20px auto;
    width: auto;

    .premium-info-line {
      align-items: center;
      display: flex;
      padding: 5px 0;
      text-transform: capitalize;
      white-space: nowrap;

      .icon {
        color: var(--green-color);
        margin-right: 15px;
      }
    }
  }

  .convert-v1-license-link {
    font-size: 14px;
    opacity: 0.5;
    padding: 5px 0;
  }

  .footer {
    color: #7c7c7c;
    font-size: 0.9em;
    padding-top: 20px;
    text-align: center;
  }

}
</style>